import {Pipe, PipeTransform} from '@angular/core';


/**
 * Takes a date and determines the 'seconds/minutes/... ago' time
 */
@Pipe({
  standalone: true,
  name: 'dateAgo'
})

/**
 * Takes a date and determines the 'seconds/minutes/... ago' time
 */
export class DateAgoPipe implements PipeTransform {

  /**
   * Executes the pipe
   *
   * @param value Date or DateString parameter to be determined
   * @return Returns an '... ago' string
   */
  transform(value?: string | Date): string | Date | undefined {

    if (!value) {
      return undefined;
    }

    type TimeInterval = 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second';


    const intervals: Record<TimeInterval, number> = {
      'year': 31536000,
      'month': 2592000,
      'week': 604800,
      'day': 86400,
      'hour': 3600,
      'minute': 60,
      'second': 1
    };

    const seconds = Math.floor((Date.now() - +new Date(value)) / 1000);

    for (const [interval, intervalSeconds] of Object.entries(intervals)) {
      const counter = Math.floor(seconds / Number(intervalSeconds));

      if (counter > 0) {
        const pluralSuffix = counter > 1 ? 's' : '';
        return `${counter} ${interval}${pluralSuffix} ago`;
      }
    }
    return value;
  }
}
