import {Component, DestroyRef} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {
  DxButtonModule,
  DxListModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule
} from "devextreme-angular";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Notification} from "../models/notification";
import {DateAgoPipe} from "../pipes/date-ago.pipe";
import {NavItem} from "../models/navItem";
import {LoginService} from "@app/sites/login/login.service";
import DataSource from "devextreme/data/data_source";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import {environment} from "../../environments/environment";
import {Actor} from "@app/models/actor";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";
import notify from "devextreme/ui/notify";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RouterLink, RouterLinkActive, DxScrollViewModule, DateAgoPipe, DxPopupModule, DxSelectBoxModule, DxTextBoxModule, DxListModule, DxButtonModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  //#region Variables

  /**
   * List of Navbar Items
   */
  navItems: NavItem[] = [
    {
      path: 'properties',
      name: 'Properties'
    } as NavItem,
    {
      path: 'actors',
      name: 'Actors'
    } as NavItem,
    {
      path: 'offers',
      name: 'Offers'
    } as NavItem]

  /**
   * List of New Creation Items
   */
  newMenuItems: NavItem[] = [
    {
      path: 'create-property',
      name: 'Property'
    } as NavItem,
    {
      path: 'new-offer',
      name: 'Offer'
    } as NavItem]

  /**
   * Represents the visibility state of the mobile menu.
   */
  mobileMenuVisible = false;

  /**
   * Show new actor popup
   */
  newActorVisible = false;

  /**
   * Represents the visibility state of the mobile creation menu.
   */
  mobileNewMenuOpen = false;

  /**
   * @description Represents the visibility of mobile notifications.
   */
  mobileNotificationsVisible = false;

  /**
   * A variable that represents an HTTP GET request to fetch notifications from a JSON file.
   */
  notifications$ = this.httpClient.get<Notification[]>('/assets/data/notifications.json');

  /**
   * Name of actor to be added
   */
  newActorName: string = '';

  /**
   * Id of main actor
   */
  mainActorId?: number;

  /**
   * Datasource with all actors
   */
  actorDataSource: DataSource;

  /**
   * Datasource with all industries
   */
  industryDataSource: DataSource;

  /**
   * List of similar actors to the one to be created
   */
  similarActors: Actor[] = [];

  /**
   * Industry Id of actor
   */
  actorIndustryId?: number;

  /**
   * Name of actor to search for similar, using for debouncing
   * @private
   */
  private actorSearch = new Subject<string>();


  //#endregion

  //#region Lifecycle

  /**
   * Creates a new instance of the Constructor class.
   *
   * @param {HttpClient} httpClient - The HttpClient object used for making HTTP requests.
   */
  constructor(private httpClient: HttpClient, public loginService: LoginService, private destroyRef: DestroyRef) {
    this.actorDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'id',
        loadMethod: 'POST',
        loadUrl: environment.apiUrl + `actors`,
      }),
      sort: [{selector: 'name'}],
    });

    this.industryDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'id',
        loadMethod: 'POST',
        loadUrl: environment.apiUrl + `actors/industries`,
      }),
      sort: [{selector: 'name'}],
    });

    this.actorSearch.pipe(
      debounceTime(300), // Add a debounce time of 300 milliseconds
      distinctUntilChanged(), // Ensure the term has changed before making a request
      takeUntilDestroyed(this.destroyRef) // Clean up the observable when the component is destroyed
    ).subscribe(term => this.searchSimilarActors(term));
  }

  /**
   * Find similar actors to the one to be created
   */
  searchSimilarActors(name: string) {
    this.httpClient.get<Actor[]>(environment.apiUrl + 'actors/similar?name=' + name).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((similarActors: Actor[]) => {
        this.similarActors = similarActors;
      })
  }

  /**
   * Create a new actor
   */
  saveNewActor() {
    const actor = {
      name: this.newActorName,
      mainActorId: this.mainActorId,
      industryId: this.actorIndustryId
    } as Actor;
    this.httpClient.post<Actor>(environment.apiUrl + 'actors/new', actor).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        {
          next: () => {
            notify(`Actor ${actor.name} created`, "success", 4000);
            this.resetActorCreation();
            this.newActorVisible = false;
          },
          error: (resp: HttpErrorResponse) => {
            console.log(resp);
            notify("Actor creation failed: " + resp.error.detail, "error", 4000);
          }
        });
  }

  //#endregion
  onActorNameChanged() {
    this.actorSearch.next(this.newActorName); // Push each changed value into the observable stream
  }

  resetActorCreation(){
    this.mainActorId = undefined;
    this.similarActors = [];
    this.actorIndustryId = undefined;
    this.newActorName = "";
    this.actorSearch.next("");
  }
}
