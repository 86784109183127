<div class="h-[79px] px-[16px] lg:px-[70px]  border-b border border-detera-border flex justify-between select-none">
  <div class="flex items-center">
    <div>
      <a routerLink="/">
        <img ngSrc="./assets/logo.webp" height="30" width="189" alt="" priority>
      </a>
    </div>
    @for (navItem of navItems; track navItem) {
      <div class="navitem" [routerLink]="[navItem.path]" [routerLinkActive]="'navitem-active'"
      >
        {{ navItem.name }}
      </div>
    }
  </div>
  <div class="flex items-center bg-[#F5F5F5] px-2 h-[60px] mt-2 rounded-full">


    <!--#region New Creation-->
    <div
      class="w-[50px] h-[50px] hidden lg:flex bg-detera-red-800 rounded-full mr-[10px] items-center justify-center user-menu cursor-auto">
      <i class="fa-regular fa-plus fa-fw text-white text-xl"></i>

      <div class="user-dropdown w-[207px]">
        <ul>
          <a (click)="newActorVisible = true" class="text-black">
            <li class="hover:bg-gray-100 cursor-pointer"><i class="fa-solid fa-user fa-fw pr-2"></i> Actor</li>
          </a>
          <a [routerLink]="['/new-offer']" class="text-black">
            <li class="hover:bg-gray-100 cursor-pointer"><i class="fa-solid fa-briefcase fa-fw pr-2"></i> Offer</li>
          </a>
          <a [routerLink]="['/new-property']" class="text-black">
            <li class="hover:bg-gray-100 cursor-pointer"><i class="fa-solid fa-building fa-fw pr-2"></i> Property</li>
          </a>
        </ul>
      </div>
    </div>
    <!--#endregion-->
    <!--#region Notifications-->
    <div
      class="w-[50px] h-[50px] hidden lg:flex bg-detera-red-800 rounded-full mr-[10px]  items-center justify-center notification-menu cursor-pointer">
      <i class="fa-regular fa-bell fa-fw text-white text-xl"></i>
      <div class="notification-dropdown w-[444px] h-[511px] cursor-auto">
        <div class="font-bold text-xl px-6 py-4 border-b border-detera-border">Notifications</div>
        <dx-scroll-view height="450px">
          @if (notifications$ | async; as notifications) {
            @for (notification of notifications; track notification) {
              <div
                class="h-[122px] py-4 border-b border-detera-border hover:bg-[#ea7b811a]">
                <div class="px-6">
                  <div class="h-[45px]">
                    <div class=" line-clamp-2">{{ notification.message }}</div>
                  </div>
                  <div><a [routerLink]="['comparables']" class="text-xs underline text-detera-red-800">Check it out</a>
                  </div>
                  <div class="text-xs text-gray-400 pt-2">{{ notification.date | dateAgo }}</div>
                </div>
              </div>
            }
          }
        </dx-scroll-view>
      </div>
    </div>
    <!--endregion-->
    <div class="w-[50px] h-[50px] lg:hidden bg-detera-red-800 rounded-full mr-[10px] flex items-center justify-center">
      <div
        (click)="mobileNotificationsVisible = false; mobileMenuVisible = false; mobileNewMenuOpen = !mobileNewMenuOpen"
        class="w-[50px] h-[50px] bg-detera-red-800 rounded-full flex items-center justify-center notification-menu-mobile cursor-pointer">
        <i class="fa-regular fa-plus fa-fw text-white text-xl"></i>
      </div>
    </div>
    <!--#region Mobile Notifications-->
    <div class="w-[50px] h-[50px] lg:hidden bg-detera-red-800 rounded-full mr-[10px] flex items-center justify-center">
      <div
        class="w-[50px] h-[50px] bg-detera-red-800 rounded-full flex items-center justify-center notification-menu-mobile cursor-pointer"
        (click)="mobileMenuVisible = false; mobileNewMenuOpen = false; mobileNotificationsVisible = !mobileNotificationsVisible">
        <i class="fa-regular fa-bell fa-fw text-white text-xl"></i>
      </div>
    </div>
    <!--endregion-->
    <!--#region Mobile Menu-->
    <div
      class="w-[50px] h-[50px]  lg:hidden rounded-full bg-[#183143] flex items-center justify-center user-menu-mobile cursor-pointer"
      (click)="mobileNotificationsVisible = false; mobileNewMenuOpen = false; mobileMenuVisible = !mobileMenuVisible">
      <img src="./assets/icons/hamburger.svg" width="25px" alt="notificationMenu">
    </div>
    <!--#endregion-->

    <!--#region User-->
    <div
      class="w-[50px] h-[50px] hidden lg:block bg-detera-red-800 rounded-full bg-[url('/assets/avatar.webp')] bg-center bg-cover user-menu cursor-auto">
      <div class="user-dropdown w-[207px]">
        <ul>
          <li class="hover:bg-gray-100 cursor-pointer"><i class="fa-solid fa-user fa-fw pr-2"></i> Account</li>
          <li class="hover:bg-gray-100 cursor-pointer"><i class="fa-solid fa-toolbox fa-fw pr-2"></i> Admin area</li>
          <a [routerLink]="['/settings']" class="text-black">
            <li class="hover:bg-gray-100 cursor-pointer"><i class="fa-solid fa-gears fa-fw pr-2"></i> Settings</li>
          </a>
          <a (click)="loginService.logout()" class="text-black">
            <li class="hover:bg-gray-100 cursor-pointer"><i class="fa-solid fa-right-from-bracket fa-fw pr-2"></i>
              Logout
            </li>
          </a>
        </ul>
      </div>
    </div>
    <!--#endregion-->
  </div>
</div>
@if (mobileNotificationsVisible) {
  <div class="notification-dropdown-mobile w-full h-[511px]">
    <div class="font-bold text-xl px-6 py-4 border-b border-detera-border">Notifications</div>
    <dx-scroll-view height="450px">
      @if (notifications$ | async; as notifications) {
        @for (notification of notifications; track notification) {
          <div
            class="h-[122px] py-4 border-b border-detera-border hover:bg-[#ea7b811a]">
            <div class="px-6">
              <div class="h-[45px]">
                <div class=" line-clamp-2">{{ notification.message }}</div>
              </div>
              <div><a [routerLink]="['comparables']" class="text-xs underline text-detera-red-800">Check it out</a>
              </div>
              <div class="text-xs text-gray-400 pt-2">{{ notification.date | dateAgo }}</div>
            </div>
          </div>
        }
      }
    </dx-scroll-view>
  </div>
}
@if (mobileMenuVisible) {
  <div class="user-dropdown-mobile w-full">
    <ul>
      @for (navItem of navItems; track navItem) {
        <li [routerLink]="[navItem.path]" [routerLinkActive]="'navitem-active'"
            (click)="mobileMenuVisible = false">
          {{ navItem.name }}
        </li>
      }
      <li (click)="mobileMenuVisible = false">Account</li>
      <li (click)="mobileMenuVisible = false">Admin area</li>
      <li (click)="mobileMenuVisible = false" [routerLink]="'/settings'">Settings</li>
      <li (click)="mobileMenuVisible = false; loginService.logout();">Logout</li>
    </ul>
  </div>
}

@if (mobileNewMenuOpen) {
  <div class="user-dropdown-mobile w-full">
    <ul>
      <li
        (click)="newActorVisible = true">
        Actor
      </li>
      @for (navItem of newMenuItems; track navItem) {
        <li [routerLink]="[navItem.path]" [routerLinkActive]="'navitem-active'"
            (click)="mobileNewMenuOpen = false">
          {{ navItem.name }}
        </li>
      }
    </ul>
  </div>
}

<dx-popup height="600px" width="800px" [(visible)]="newActorVisible" title="New Actor" (onHidden)="resetActorCreation()">
  <div class="p-4 grid grid-cols-2 gap-4">
    <div>
      <div>
        <div class="cell-label pb-2">
          New actor name
        </div>
        <div class="cell-text">
          <dx-text-box [(value)]="newActorName" (onValueChanged)="onActorNameChanged()"
                       [valueChangeEvent]="'keyup'"></dx-text-box>
        </div>
      </div>
      <div>
        <div class="cell-label pb-2 mt-4">
          Industry
        </div>
        <div class="cell-text">
          <dx-select-box [(dataSource)]="industryDataSource" [(value)]="actorIndustryId" displayExpr="name" valueExpr="id"></dx-select-box>
        </div>
      </div>
      <div>
        <div class="cell-label pb-2 mt-4">
          Main Actor
        </div>
        <div class="cell-text">
          <dx-select-box [(dataSource)]="actorDataSource" [(value)]="mainActorId" displayExpr="name" valueExpr="id"></dx-select-box>
        </div>
      </div>
      <div class="flex justify-end">
        <dx-button type="default" text="Save" class="mt-4" [disabled]="!newActorName" (onClick)="saveNewActor()"></dx-button>
      </div>
    </div>
    <div>
      <div>
        <div class="cell-label pb-2">
          Similar Actors
        </div>
        <div class="cell-text">
          <dx-list [(dataSource)]="similarActors" height="430px" noDataText="No similar actors found"
                   displayExpr="name"></dx-list>
        </div>
      </div>
    </div>

  </div>
</dx-popup>
